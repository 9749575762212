import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import { fetchAuthenticatedData, postAuthenticatedData } from "../../redux/api";
import { toast } from "react-toastify";
import { Button, Col, Modal, Row } from "react-bootstrap";
// import { createToken, formatCVC, formatExpirationDate } from "../../utils";
import Cards from "react-credit-cards";


export const bankList = [
    { title: "ACCESS BANK NIGERIA", code: "044" },
    { title: "ACCESS MOBILE", code: "323" },
    { title: "Access Bank", code: "044" },
    { title: "AFRIBANK NIGERIA PLC", code: "014" },
    { title: "Aso Savings and Loans", code: "401" },
    { title: "DIAMOND BANK PLC", code: "063" },
    { title: "EcoBank PLC", code: "050" },
    { title: "ECOBANK NIGERIA PLC", code: "050" },
    { title: "Ecobank Mobile", code: "307" },
    { title: "ENTERPRISE BANK LIMITED", code: "084" },
    { title: "FBN MOBILE", code: "309" },
    { title: "FIDELITY BANK PLC", code: "070" },
    { title: "Fidelity Bank", code: "070" },
    { title: "FINATRUST MICROFINANCE BANK", code: "608" },
    { title: "FIRST BANK PLC", code: "011" },
    { title: "First Bank PLC", code: "011" },
    { title: "FIRST CITY MONUMENT BANK PLC", code: "214" },
    { title: "First City Monument Bank", code: "214" },
    { title: "Globus Bank", code: "103" },
    { title: "GTBANK PLC", code: "058" },
    { title: "GTBank Mobile Money", code: "315" },
    { title: "HERITAGE BANK", code: "030" },
    { title: "JAIZ BANK", code: "301" },
    { title: "KEYSTONE BANK PLC", code: "082" },
    { title: "Paga", code: "327" },
    { title: "Parkway", code: "311" },
    { title: "PAYCOM", code: "305" },
    { title: "Polaris Bank", code: "076" },
    { title: "Polaris bank", code: "076" },
    { title: "Providus Bank PLC", code: "101" },
    { title: "ProvidusBank PLC", code: "101" },
    { title: "Rand merchant Bank", code: "502" },
    { title: "SKYE BANK PLC", code: "076" },
    { title: "STANBIC IBTC BANK PLC", code: "221" },
    { title: "Stanbic Mobile", code: "304" },
    { title: "STANDARD CHARTERED BANK NIGERIA LIMITED", code: "068" },
    { title: "STERLING BANK PLC", code: "232" },
    { title: "Suntrust Bank", code: "100" },
    { title: "UNION BANK OF NIGERIA PLC", code: "032" },
    { title: "Union Bank PLC", code: "032" },
    { title: "UNITED BANK FOR AFRICA PLC", code: "033" },
    { title: "United Bank for Africa", code: "033" },
    { title: "UNITY BANK PLC", code: "215" },
    { title: "WEMA BANK PLC", code: "035" },
    { title: "Wema Bank PLC", code: "035" },
    { title: "ZENITH BANK PLC", code: "057" },
    { title: "Zenith Bank PLC", code: "057" },
    { title: "ZENITH Mobile", code: "322" },
  ];

  export const accountTypes = [
    { code: "00", title: "Default" },
    { code: "10", title: "Savings Account" },
    { code: "20", title: "Current Account" },
  ];

const AddAccount = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [focus, setFocus] = useState("name");
    const [loading, setLoading] = useState(false);
  const routes = all_routes;
  const navigate = useNavigate();
    const toggle = (e:any) => {
      e.preventDefault();
      setModalIsOpen((p) => !p);
    };
    const [form, setForm] = useState<any>({
      exp: "",
      account_name: "",
      account_number: "",
      account_type: "",
      card: "",
      pin: "",
      cvv2: "",
      type: "",
    });
  
    const handleInputChange = (event:any) => {
      const { name, value } = event.target;
      setForm({ ...form, [name]: value });
    };
  
    const handleInputChangeCVV = (event:any) => {
      const { name, value } = event.target;
      // setForm({ ...form, [name]: formatCVC(value) });
    };
  
    const handleInputChangeNum = (event:any) => {
      const { name, value } = event.target;
      setForm({ ...form, [name]: value });
    };
  
    const handleSubmit = (e:any) => {
      e.preventDefault();
      setLoading(true);
      const card_info = {
        card: form.card,
        pin: form.pin,
        expiry_date: form?.exp.replace("/", ""),
        cvv2: form.cvv2 ? form.cvv2 : "000",
      };
      // const token = createToken(card_info);
      const data = {
        // pan_info: token,
        account_name: form.account_name,
        account_number: form.account_number,
        account_type: form.account_type,
        bank_cbn_code: form.bank_cbn_code,
        type: form.type,
      };
      postAuthenticatedData("/pan_details/create", data)
        .then((resp) => {
          if (resp.success) {
            toast.success("Success");
            navigate(-2);
          } else {
            toast.error("Failed");
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    };

  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content content-two">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="mb-1">Add New Account</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">Finance & Accounts
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Add New Account
                    </li>
                  </ol>
                </nav>
              </div>
            </div>

            {/* /Page Header */}
            <div className="row">
              <div className="col-md-12">
                <form >
                <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-info-square-rounded fs-16" />
                        </span>
                        <h4 className="text-dark">Card Information</h4>
                      </div>
                    </div>
                    {/* {JSON.stringify(form)} */}
                    <div className="card-body pb-1">
                      <div className="add-section">

                      <Row>
              <div className="col-md-4 my-1">
                <label>Select Bank:</label>
                <select
                  onChange={handleInputChange}
                  value={form.bank_cbn_code}
                  name="bank_cbn_code"
                  className="form-control"
                  required
                >
                  <option value={""}>--select--</option>
                  {bankList.map((item, id) => (
                    <option key={id} value={item.code}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {/* <input value={form.bank_cbn_code} name='bank_cbn_code' type="text" className="form-control" placeholder="Enter account number" /> */}
              </div>
              <div className="col-md-4 my-1">
                <label>Account Number:</label>
                <input
                  onChange={handleInputChange}
                  value={form.account_number}
                  name="account_number"
                  type="text"
                  required
                  className="form-control"
                  placeholder="Enter account number"
                />
              </div>
              <div className="col-md-4 my-1">
                <label>Account Name:</label>
                <input
                  onChange={handleInputChange}
                  value={form.account_name}
                  name="account_name"
                  type="text"
                  required
                  className="form-control"
                  placeholder="Enter account name"
                />
              </div>

              <div className="col-md-4 my-1">
                <label>Account Type:</label>
                <select
                  onChange={handleInputChange}
                  name="account_type"
                  //   required
                  className="form-control"
                >
                  <option value={""}>--select--</option>
                  {accountTypes.map((item, id) => (
                    <option key={id} value={item.code}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {/* <input value={form.account_type} name='account_type' type="text" className="form-control" placeholder="Enter account number" /> */}
              </div>

              <div className="col-md-4 my-1">
                <label>Card:</label>
                <input
                  onChange={handleInputChangeNum}
                  value={form.card}
                  name="card"
                  className="form-control"
                  placeholder="Enter card number"
                  type="tel"
                  pattern="[\d| ]{16,22}"
                  maxLength={19}
                  required
                />
              </div>

              {/* {JSON.stringify(form)} */}
              <div className="col-md-4 my-1">
                <label>Expiry Date:</label>
                <input
                  onChange={({ target }) => {
                    // target.value = formatExpirationDate(target.value);
                    const { name, value } = target;
                    setForm({ ...form, [name]: value });
                  }}
                  value={form.exp}
                  name="exp"
                  type="tel"
                  className="form-control"
                  placeholder="YY/MM"
                  pattern="\d\d/\d\d"
                  required
                />
              </div>
              <div className="col-md-4 my-1">
                <label>CVV:</label>
                <input
                  onChange={handleInputChangeCVV}
                  value={form.cvv2}
                  name="cvv2"
                  type="tel"
                  //   required
                  className="form-control"
                  pattern="\d{3}"
                  placeholder="Enter cvv2 code eg. "
                />
              </div>
              <div className="col-md-4 my-1">
                <label>PIN:</label>
                <input
                  onChange={handleInputChange}
                  value={form.pin}
                  name="pin"
                  type="password"
                  required
                  maxLength={4}
                  className="form-control"
                  placeholder="Enter pin number"
                />
              </div>
              {/* <PinInput /> */}
              {/* <OtpInput
                value={form.pin}
                onChange={(e) => {
                  setForm({ ...form, pin: e });
                }}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input name="pin" type="password" {...props} />
                )}
              /> */}
              <div className="col-md-4 my-1">
                <label>Type of Account:</label>
                <select
                  onChange={handleInputChange}
                  value={form.type}
                  name="type"
                  className="form-control"
                  required
                >
                  <option value={""}>--select--</option>
                  {["MAIN ACCOUNT", "SUB-TREASURY ACCOUNT", "OTHERS"].map(
                    (item, id) => (
                      <option key={id} value={item}>
                        {item}
                      </option>
                    )
                  )}
                </select>
              </div>
              <center className="my-2">
                <Button type="submit">Submit</Button>
              </center>
            </Row>
                        </div>
                        </div>
                        </div>
                        <Modal show={modalIsOpen} 
                        // onHide={toggle}
                        >
          <Modal.Header>Bank Info Preview</Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <div
                  onClick={() =>
                    setFocus((p) => (p === "cvc" ? "name" : "cvc"))
                  }
                >
                  <Cards
                    cvc={form.cvv2 || "000"}
                    expiry={form.exp}
                    // focused={focus}
                    name={form.account_name}
                    number={form.card}
                    // preview={true}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="mt-2">
                <h6 className="text-center">
                  Account: {form.account_name}({form.account_number})
                </h6>
              </Col>
            </Row>
            <h5 className="text-center">
              The card details are encrypted end-to-end.
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" onClick={toggle}>
              Close
            </Button>
            <Button  disabled={loading}
           size="sm" onClick={handleSubmit}>
              {loading ? "Please Wait..." : "Submit"}
            </Button>
          </Modal.Footer>
        </Modal>

                    </form>
                    </div>
                    </div>
                    </div>
                    </div>
                    </>
                    </div>
  );
};

export default AddAccount;
