import React, { useState } from "react";
import { Link } from "react-router-dom";
import PredefinedDateRanges from "../../../core/common/datePicker";
import { TableData } from "../../../core/data/interface";
import Table from "../../../core/common/dataTable/index";
import { permission } from "../../../core/data/json/permission";
import { all_routes } from "../../router/all_routes";
import TooltipOption from "../../../core/common/tooltipOption";
import { permissionData } from "./permissionData";
import { Row } from "react-bootstrap";

type PermissionType = {
    module: string;
    access: {
        create: boolean,
        view: boolean,
        edit: boolean,
        delete: boolean,
    }
}

type AppPermissionType = {
    app: string;
    permissions: PermissionType[]
}

type PermissionArrayType = AppPermissionType[]


const DesignationPermissions = () => {
  const data = permissionData;
  const routes = all_routes;

  const [selectedPermissions, setSelectedPermissions] = useState<PermissionArrayType>([])

  const handlChecked = (item:any, type:string) => {
    // let appIsSelected:AppPermissionType = selectedPermissions.find((a:any) => a.app === item.app)
    // // let checked = false;
    //     if(appIsSelected) {
    //         // checked =  appIsSelected?.permission[record.module]['create'] === true
    //         let moduleIsSelected:any = appIsSelected.permissions.find(perm => perm.module === item.module)

    //         if(moduleIsSelected) {}
    //         else {
    //             setSelectedPermissions((prev:PermissionArrayType) => [
    //                 ...prev,
                    
    //             ])
    //         }
    //     } else {
    //         setSelectedPermissions((prev:any[]) => [
    //             ...prev,
    //             {
    //                 app: item.app,
    //                 permissions: [
    //                     {
    //                         module: item.module,
    //                         access: {
    //                             [type]: true
    //                         }
    //                     }
    //                 ]
    //             }
    //         ])
    //     }
  }

  const columns = [
    {
      title: "Modules",
      dataIndex: "modules",
      sorter: (a: TableData, b: TableData) =>
        a.modules.length - b.modules.length,
    },
    {
      title: "Create",
      dataIndex: "create",
      render: (text: string, record: any, index: number) => {
        let appIsSelected:any = selectedPermissions.find((a:any) => a.app === record.app)

        let checked = false;
        if(appIsSelected) {
            checked =  appIsSelected?.permission[record.module]['create'] === true
        }
        return(
        <>
        {/* {JSON.stringify(record)} */}
          <label className="checkboxs">
            <input type="checkbox" checked={checked} onChange={() => {/**handleChecked(record) */}} />
            <span className="checkmarks" />
          </label>
        </>
      )},
    //   sorter: (a: TableData, b: TableData) =>
    //     a.created.length - b.created.length,
    },
    {
      title: "View",
      dataIndex: "view",
      render: (text: string, record: any, index: number) => (
        <>
          <label className="checkboxs">
            <input type="checkbox" />
            <span className="checkmarks" />
          </label>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.view.length - b.view.length,
    },
    {
      title: "Edit",
      dataIndex: "edit",
      render: (text: string, record: any, index: number) => (
        <>
          <label className="checkboxs">
            <input type="checkbox" />
            <span className="checkmarks" />
          </label>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.edit.length - b.edit.length,
    },
    {
      title: "Delete",
      dataIndex: "delete",
      render: (text: string, record: any, index: number) => (
        <>
          <label className="checkboxs">
            <input type="checkbox" />
            <span className="checkmarks" />
          </label>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.delete.length - b.delete.length,
    },
    {
      title: "AllowAll",
      dataIndex: "allowAll",
      render: (text: string, record: any, index: number) => (
        <>
          <label className="checkboxs">
            <input type="checkbox" />
            <span className="checkmarks" />
          </label>
        </>
      ),
      sorter: (a: TableData, b: TableData) =>
        a.allowAll.length - b.allowAll.length,
    },
  ];
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Designation Permissions</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">User Management</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Designation Permissions
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                <TooltipOption />
                
              </div>
            </div>
            {/* /Page Header */}

             <Row>
             {data.map((app:any, idx:number) => {
                let appData = app.permissions.map((i:any) => ({
                    app: app.app,
                    modules: i.module,
                    create: i.create,
                    view: i.view,
                    edit: i.edit,
                    delete: i.create,
                }))
                return(
            <div className="col-md-6 card" key={idx}>
                 <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                 <h4 className="mb-3">{app.app}</h4>
                 {/* {JSON.stringify({appData})} */}
                 </div>
                <div className="card-body p-0 py-3">
                <Table noSearch columns={columns} dataSource={appData} Selection={true} />
             </div>
            </div>
             )})}
             </Row>

          </div>
        </div>
        {/* /Page Wrapper */}
      </>
    </div>
  );
};

export default DesignationPermissions;
