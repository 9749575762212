import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../core/common/dataTable/index";
import { manageusersData } from "../../core/data/json/manageuser";
import { TableData } from "../../core/data/interface";
import PredefinedDateRanges from "../../core/common/datePicker";
import CommonSelect from "../../core/common/commonSelect";
import { Reason } from "../../core/common/selectoption/selectoption";
import { all_routes } from "../router/all_routes";
import TooltipOption from "../../core/common/tooltipOption";
import { fetchAuthenticatedData } from "../../redux/api";
import Loading from "../../core/common/Loading";
import { Button } from "antd";

const Manageusers = () => {
  const routes = all_routes;
  const navigate = useNavigate()
  const data = manageusersData;
  
  const [loading,setLoading] = useState(false)
  const [usersList, setUserList] = useState([])


  const getStaffList = () => {
    setLoading(true)
    fetchAuthenticatedData('/api/users')
    .then(res => {
      setLoading(false)
      if(res && res.length) {
        setUserList(res)
      }
    })
    .catch(err => {
      setLoading(false)
      console.log(err)
    })
  }

  useEffect(() => {
    getStaffList()
  }, [])


  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    },

    {
      title: "Name",
      dataIndex: "fullName",
      // sorter: (a: TableData, b: TableData) => a.fullName.length - b.fullName.length,
    },
    {
      title: "Department",
      dataIndex: "department",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
 
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to={routes.staffDetails}>{record?.department?.departmentName}</Link>
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
 
          <div className="ms-2">
            <p className="text-dark mb-0">
             {record?.username}
            </p>
          </div>
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (text: string, record:any) => (
        <>
          {record.active ? (
            <span className="badge badge-soft-success d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              Active
            </span>
          ) : (
            <span className="badge badge-soft-danger d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              Inactive
            </span>
          )}
        </>
      ),
      sorter: (a: any, b: any) => a.status.length - b.status.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text:string,record:any) => (
        <>
          <div className="d-flex align-items-center">
            <Button className="me-2" type="primary" onClick={() => navigate(`/user-management/view-users/${record.id}`)}>View</Button>
            <Button type="primary" color="red">Suspend</Button>
            
          </div>
        </>
      ),
    },
  ];
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Users</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">User Management</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Users
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
                {/* <div className="mb-2">
                  <Link
                    to="#"
                    className="btn btn-primary d-flex align-items-center"
                  >
                    <i className="ti ti-square-rounded-plus me-2" />
                    Add User
                  </Link>
                </div> */}
              </div>
            </div>
            {/* /Page Header */}
            {/* Filter Section */}
            <div className="card">
        
              {/* User List */}
              <div className="card-body p-0 py-3">
              {loading ? <center className="my-2"><Loading /></center> : null}
                <Table columns={columns} dataSource={usersList} Selection={true} />
              </div>
              {/* /User List */}
            </div>
            {/* /Filter Section */}
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="datatable-paginate mt-4" />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
    </div>
  );
};

export default Manageusers;
