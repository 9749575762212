import React, { useCallback, useEffect, useRef, useState } from "react";
import Table from "../../../../core/common/dataTable/index";
import { TableData } from "../../../../core/data/interface";
import { payroll } from "../../../../core/data/json/pay-roll";
import PredefinedDateRanges from "../../../../core/common/datePicker";
import CommonSelect from "../../../../core/common/commonSelect";
import {
    Contract,
  month,
  monthOptions,
  staffName,
  year,
} from "../../../../core/common/selectoption/selectoption";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import TooltipOption from "../../../../core/common/tooltipOption";
import { fetchAuthenticatedData, postAuthenticatedData } from "../../../../redux/api";
import { Row, Spinner } from "react-bootstrap";
import moment from "moment";
import PaySlip from "./PaySlip";
import { PayrollItemType } from "../../staff-list/staff-payroll.tsx/edit-staff-payroll";
import { toast } from "react-toastify";

export type DepartmentType = {
  "id":number;
  "departmentName"?:string;
}

export type UserType = {
  "id":number,
  "username":string,
  "fullName":string,
  "role":string,
  "active":boolean,
  "departmentId":number,
  "department": DepartmentType,
  staff_payroll_items: [PayrollItemType]
}

export type EmployeeType = {
  id:string;
  designation:string;
  salaryBasic:number;
  allowanceTotal: number;
  deductionTotal: number;
  salaryNet:number;
  user: UserType
}


const ApprovePayroll = () => {
  const data = payroll;
  const [staffList, setStaffList] = useState([])
  const [form, setForm] = useState<any>({
    selectedMonth: '',
    selectedDepartment : '',
    selectedStaff: ''
  })
  const [loading, setLoading] = useState(false)
  const [submitting,setSubmitting] = useState(false)
  const [report,setReport] = useState([])
  const [activePayslip, setActivePayslip] = useState<EmployeeType|undefined>()

//   const getStaffList = () => {
//     fetchAuthenticatedData('/api/users')
//     .then(res => {
//       if(res && res.length) {
//         setStaffList(res)
//       }
//     })
//     .catch(err => {
//       console.log(err)
//     })
//   }

//   useEffect(() => {
//     getStaffList()
//   }, [])

  const getData = () => {
    // fetchAuthenticatedData(`/api/payrollEntry/get-entries/${form.selectedMonth}/${moment().format("YYYY")}`)
    // fetchAuthenticatedData(`/api/payrollEntry`)
    fetchAuthenticatedData('/api/paymentItems/payroll/review?status=Reviewed')
    .then(res => {
        if(res && res.length) {
          setReport(res)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getData()
  }, [])

//   const [departmentList, setDepartmentList] = useState([])

//   const getDepartmentlist = () => {
//     fetchAuthenticatedData('/api/departments')
//     .then(res => {
//       setDepartmentList(res)
//     })
//     .catch(err => {
//       console.log(err)
//     })
//   }

//   useEffect(() => {
//     getDepartmentlist()
//   }, [])

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    },

    {
      title: "Name",
      dataIndex: "fullName",
      sorter: (a: TableData, b: TableData) => a.fullname.length - b.fullname.length,
      render: (text: string, record: any, index: number) => (
        <>
          <span>
            {record?.user?.fullName}
          </span>
        </>
      ),
    },
    {
      title: "Department",
      dataIndex: "department",
      render: (text: string, record: any, index: number) => (
        <>
          <span>
            {record?.user?.department?.departmentName}
          </span>
        </>
      ),
      // sorter: (a: TableData, b: TableData) =>
      //   a.department.length - b.department.length,
    },
    // {
    //   title: "Designation",
    //   dataIndex: "designation",
    //   sorter: (a: TableData, b: TableData) =>
    //     a.designation.length - b.designation.length,
    // },
    {
      title: "Employment Type",
      dataIndex: "designation",
      render: (text: string, record: any, index: number) => (
        <>
          <span>
            {record?.user?.user_financial_info?.employmentType}
          </span>
        </>
      ),
      sorter: (a: TableData, b: TableData) =>
        a.designation.length - b.designation.length,
    },
    {
      title: "Gross Salary",
      dataIndex: "phone",
      render: (text: string, record: any, index: number) => (
        <>
          <span>
            {(record?.totalAllowances + record?.basic)?.toLocaleString()}
          </span>
        </>
      ),
      // sorter: (a: TableData, b: TableData) => a.phone.length - b.phone.length,
    },
    {
      title: "Deductions",
      dataIndex: "amount",
      render: (text:string, record:any) => (
        <>
          <span>
            {record?.totalDeductions?.toLocaleString()}
          </span>
        </>
      )
      // sorter: (a: TableData, b: TableData) => a.amount.length - b.amount.length,
    },
    {
      title: "Net Salary",
      dataIndex: "amount",
      render: (text:string, record:any) => (
        <>
          <span>
            {record?.netSalary?.toLocaleString()}
          </span>
        </>
      )
      // sorter: (a: TableData, b: TableData) => a.amount.length - b.amount.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: string, record:any) => (
        <>
          {record?.user?.active ? (
            <span className="badge badge-soft-success d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              Active
            </span>
          ) : (
            <span className="badge badge-soft-danger d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
             Not Active
            </span>
           )}
        </>
      ),
      sorter: (a: any, b: any) => a.status.length - b.status.length,
    },
    {
      title: "",
      dataIndex: "details",
      render: (text: string, record:any) => (
        <>

                  <button
onClick={() => setActivePayslip(record)}
                    className="btn btn-primary d-flex align-items-center"
                    data-bs-toggle="modal"
                    data-bs-target="#view_payslip"
                  >
                    <i className="ti ti-square-rounded-plus me-2" />
                    Preview Payslip
                  </button>
          {/* <Link to="#" className="btn btn-light add-fee">
            Generate Payslip
          </Link> */}
        </>
      ),
      sorter: (a: TableData, b: TableData) =>
        a.details.length - b.details.length,
    },
  ];
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const routes = all_routes;

  const handleSubmit = () => {
    setSubmitting(true)
    console.log(report)
    let items:any[] = report.map((r:any) => r.id)

    postAuthenticatedData('/api/paymentItems/payroll/review', {items, status: "Reviewed"})
    .then(resp => {
      toast(resp.message)
    }).catch(err => {
      console.log(err)
      toast(err.message, { type: 'error' })
    }).finally(() => setSubmitting(false))
  }

  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            <PaySlip employee={activePayslip}/>
            {/* Page Header */}
            {/* <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Payroll</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">HRM</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Payroll
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
              </div>
            </div> */}
            {/* Page Header*/}
            {/* Filter Section */}
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                <h4 className="mb-3">Approve Staff Payroll</h4>
                <div className="d-flex align-items-center flex-wrap pb-2">
                 {report.length ? 
                 <>
                 <button className="btn btn-danger me-1" onClick={handleSubmit}>Send Back</button> 
                 <button className="btn btn-primary" onClick={handleSubmit}>Approve Payroll</button> 
                 </>
                 : null}
               
                </div>
              </div>
              {/* <Row className=' m-2'>
              <div className='col-md-3'>
                <label className="fw-bold">Select Month</label>
                <CommonSelect
                    className="select"
                    options={monthOptions.map((m) => ({...m, label: m.label + ", " + moment().year()}))}
                    value={{label: form?.selectedMonthName, value: form.selectedMonth}}
                    handleChange={(option:any) => setForm((prev:any) => ({...prev, selectedMonthName: option.label, selectedMonth: option.value }))}
                />
              </div>

              <div className='col-md-3'>
                <label className="fw-bold">Select Department</label>
                <CommonSelect
                    className="select"
                    options={departmentList.map((d:any) => ({label: d.departmentName, value: d.id }))}
                    value={{label: form.selectedDepartmentName, value: form.selectedDepartment}}
                    handleChange={(option:any) => setForm((prev:any) => ({...prev, selectedDepartmentName: option.label, selectedDepartment: option.value }))}
                />
              </div>


              <div className='col-md-3'>
                <label className="fw-bold">Select Staff</label>
                <CommonSelect
                    className="select"
                    options={staffList.map((s:any) => ({label:s.fullName, value: s.id}))}
                    value={{label: form.selectedStaffName, value: form.selectedStaff}}
                    handleChange={(option:any) => setForm((prev:any) => ({...prev, selectedStaffName:option.label, selectedStaff: option.value }))}
                />
              </div>
              <div className="col-md-3 d-flex flex-direction-row justify-content-center">
                <button onClick={getData} className='btn btn-light w-100'>SEARCH</button>
              </div>
              </Row> */}
              <div className="card-body p-0 py-3">
                {loading && <Spinner />}
                {/* {JSON.stringify(report)} */}
                {/* Payroll List */}
               <Table columns={columns} dataSource={report} Selection={true} />
                {/* /Payroll List */}
              </div>
            </div>
            {/* /Filter Section */}
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
    </div>
  );
};

export default ApprovePayroll;
