import React, { useCallback, useEffect, useState } from 'react'
import StaffBasicInfo from '../hrm/staff-list/staff-details.tsx/StaffBasicInfo'
import { useParams } from 'react-router'
import { fetchAuthenticatedData } from '../../redux/api'
import { StaffPayrollType } from '../hrm/staff-list/staff-payroll.tsx'
import { all_routes } from '../router/all_routes'

function ViewUser() {
  const userId = useParams().user_id
  const routes = all_routes
  const [userInfo,setUserInfo ] = useState<StaffPayrollType>({
    "id":0,"username":"","fullName":"",
    "role":"","active":true,"departmentId":0,
    user_financial_info: {
      "id":0,"employmentType":"","salaryBasic":"0","salaryGross":0,"salaryNet":0,"allowanceTotal":0,"deductionTotal":0,
      "bankName":"","accountName":"","accountNumber":"","iban":"","userId":0
    },
    user_personal_info: {
      "id":0,"dateOfBirth":"","gender":"","maritalStatus":"","fatherName":"","idNumber":"","address":"","city":"","country":"",
      "mobile":"","phone":"","emailAddress":"","userId":0
    },
  })

  const getStaffInfo = useCallback(() => {
    fetchAuthenticatedData ('/api/users/' + userId)
  .then(res => {
      // let user = res
      setUserInfo(res)

   
  })
  .catch(err => {
      console.log(err)
  })
  },[userId])

  
  useEffect(() => {
    getStaffInfo()
  }, [getStaffInfo])

  return (
    <>
    {/* Page Wrapper */}
    <div className="page-wrapper">
      <div className="content">
      <div className="row">
      <div className="col-xxl-3 col-lg-4 theiaStickySidebar">
              <StaffBasicInfo basicInfo={userInfo} />
              </div>
              <div className="col-xxl-9 col-lg-8">
      <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-info-square-rounded fs-16" />
                        </span>
                        <h4 className="text-dark">User Information</h4>
                      </div>
                    </div>
                    {/* {JSON.stringify(form)} */}
                    <div className="card-body pb-1">
                      <div className="add-section">
                        <div className="row">
                        <div className="row row-cols-xxl-4 row-cols-md-6">
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">First Name </label>
                             <span className='form-control'></span>
                                
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Last Name </label>
                              <span className='form-control'></span>
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Gender </label>
                              <span className='form-control'></span>
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Email Address </label>
                              <span className='form-control'></span>
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Primary Contact Number </label>
                              <span className='form-control'></span>
                            </div>
                          </div>

                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Address </label>
                              <span className='form-control'></span>
                            </div>
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>
    </div>
    </div>
    </>
  )
}

export default ViewUser