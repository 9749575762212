export const permissionData = [
    {
     app: "CPMR",
     permissions: [
         {
            module:"Dashboard",
        //  access: {
        //      create: true,
        //      view: true,
        //      edit:true,
        //      delete: false
        //         },
            },
         {
            module:"My Patients",
        //  access: {
        //      create: true,
        //      view: true,
        //      edit:true,
        //      delete: false
        //         },
            },
         {
            module:"Consult Requests",
        //  access: {
        //      create: true,
        //      view: true,
        //      edit:true,
        //      delete: false
        //         },
            },
         {
            module:"Nursing",
        //  access: {
        //      create: true,
        //      view: true,
        //      edit:true,
        //      delete: false
        //         },
            },
         {
            module:"Settings",
        //  access: {
        //      create: true,
        //      view: true,
        //      edit:true,
        //      delete: false
        //         },
            },
        ]
    },
    {
     app: "Ambulatory Suite",
     permissions: [
         {
            module: "Dashboard",
        //     access: {
        //      create: true,
        //      view: true,
        //      edit:true,
        //      delete: false
        //  },
        },
         {
            module: "Check In",
        //     access: {
        //      create: true,
        //      view: true,
        //      edit:true,
        //      delete: false
        //  },
        },
         {
            module: "Triage",
        //     access: {
        //      create: true,
        //      view: true,
        //      edit:true,
        //      delete: false
        //  },
        },
         {
            module: "Billing",
        //     access: {
        //      create: true,
        //      view: true,
        //      edit:true,
        //      delete: false
        //  },
        },
         {
            module: "Laboratory",
        //     access: {
        //      create: true,
        //      view: true,
        //      edit:true,
        //      delete: false
        //  },
        },
         {
            module: "Pharmacy",
        //     access: {
        //      create: true,
        //      view: true,
        //      edit:true,
        //      delete: false
        //  },
        },
         {
            module: "Radiology",
        //     access: {
        //      create: true,
        //      view: true,
        //      edit:true,
        //      delete: false
        //  },
        },
    ]
    },
    {
        app: "HR Suite",
        permissions: [
            {
                module: "Dashboard",
            //     access: {
            //      create: true,
            //      view: true,
            //      edit:true,
            //      delete: false
            //  },
            },
            {
                module: "Staffs",
            //     access: {
            //      create: true,
            //      view: true,
            //      edit:true,
            //      delete: false
            //  },
            },
            {
                module: "Departments",
            //     access: {
            //      create: true,
            //      view: true,
            //      edit:true,
            //      delete: false
            //  },
            },
            {
                module: "Designation",
            //     access: {
            //      create: true,
            //      view: true,
            //      edit:true,
            //      delete: false
            //  },
            },
            {
                module: "Attendance"
            },
            {
                module: "My Leave Applications"
            },
            {
                module: "Approve Leaves"
            },
            {
                module: "Payroll Setup"
            },
            {
                module: "Payroll Items"
            },
            {
                module: "Process Payroll"
            },
            {
                module: "Review Payroll"
            },
            {
                module: "Approve Payroll"
            },
            {
                module: "Manage Accounts"
            },{
                module: "Expense Management"
            },{
                module: "Notice Board"
            },{
                module: "Payroll Report"
            },{
                module: "Expense Report"
            },{
                module: "Benefit Services Report"
            },{
                module: "Users"
            },
        ]
    },
    {
        app: "TMS",
        permissions: [
            {
                module: "Users"
            }
        ]
    }
 ]